import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Form, Breadcrumb, Button, Modal, Switch, message, Row, Col, Input, Select, DatePicker, InputNumber } from "antd"

import { ApplicationYears } from "../../../models/applicationYears"
import { AntDProTable } from "../../../components/antdProTable"
import { applicationService } from "../../../services/applicationService"
import { Link, useNavigate } from "react-router-dom"
import { HomeOutlined, StarFilled, StarOutlined } from "@ant-design/icons"
import { useForm } from "antd/es/form/Form"
import dayjs from "dayjs"
import Swal from "sweetalert2"
import axios from "axios"
const { RangePicker } = DatePicker;
//Start
export function ApplicationYearsListPage() {
    const { t } = useTranslation()


    const [openCloneModal, setCloneModal] = useState<ApplicationYears>()
    const [f1] = useForm();
    const navigate = useNavigate();
    const [groupId, setGroupId] = useState<string>()
    let tableRef = useRef<ActionType>()

    useEffect(() => {
        if (openCloneModal != undefined && openCloneModal.applicationPeriod == undefined) {
            let form = {
                ...openCloneModal,
                applicationPeriod: [dayjs(openCloneModal.applicationStartDate), dayjs(openCloneModal.applicationEndDate)],
                applicationVisiblePeriod: [dayjs(openCloneModal.applicationVisibleStartDate), dayjs(openCloneModal.applicationVisibleEndDate)]
            }
            setCloneModal(form)
            f1.setFieldsValue(form);
        }

    }, [openCloneModal])
    useEffect(() => {
        axios.get('/application/getGradeOptions')
            .then(res => {
                if (res && res.data && res.data.status === 'OK' && res.data.settings) {
                    if (res.data.settings.groupId) {
                        setGroupId(res.data.settings.groupId)
                    }
                }
            });
    }, [])
    const columns: ProColumns<ApplicationYears>[] = [
        {
            title: t(""),
            dataIndex: "id",
            filters: false,
            onFilter: false,
            hideInSearch: true,
            renderText(text, record, index, action) {
                return text == groupId ? <StarFilled style={{ color: "orange" }} /> : <></>
            },
        },
        {
            title: t("School Year"),
            dataIndex: "schoolYear",
            filters: true,
            onFilter: true,
            renderText(text, record, index, action) {
                if (record.applicationEndDate >= Date.now() || record.applicationVisibleEndDate >= Date.now()) {
                    return <span>{text}</span>
                } else {
                    return <span style={{ color: "lightgrey" }}>{text}</span>
                }
            },
        },
        {
            title: t("Application For"),
            dataIndex: "applicationFor",
            valueEnum: {
                "G1": { text: t("G1") },
                "G2": { text: t("G2") },
                "G3": { text: t("G3") },
                "G4": { text: t("G4") },
                "G5": { text: t("G5") },
                "G6": { text: t("G6") },
                "G26": { text: t("G26") },
            },
            renderText(text, record, index, action) {
                if (record.applicationEndDate >= Date.now() || record.applicationVisibleEndDate >= Date.now()) {
                    return <span>{text}</span>
                } else {
                    return <span style={{ color: "lightgrey" }}>{text}</span>
                }
            },
        },
        {
            title: t("Start Date"),
            dataIndex: "applicationStartDate",
            filters: true,
            onFilter: true,
            hideInSearch: true,
            renderText(text, record, index, action) {
                if (record.applicationEndDate >= Date.now() || record.applicationVisibleEndDate >= Date.now()) {
                    return <span>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                } else {
                    return <span style={{ color: "lightgrey" }}>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                }
            },
        },
        {
            title: t("End Date"),
            dataIndex: "applicationEndDate",
            filters: false,
            onFilter: false,
            hideInSearch: true,
            renderText(text, record, index, action) {
                if (record.applicationEndDate >= Date.now() || record.applicationVisibleEndDate >= Date.now()) {
                    return <span>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                } else {
                    return <span style={{ color: "lightgrey" }}>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                }
            },
        },
        {
            title: t("Visible Start Date"),
            dataIndex: "applicationVisibleStartDate",
            filters: false,
            onFilter: false,
            hideInSearch: true,
            renderText(text, record, index, action) {
                if (record.applicationEndDate >= Date.now() || record.applicationVisibleEndDate >= Date.now()) {
                    return <span>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                } else {
                    return <span style={{ color: "lightgrey" }}>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                }
            },
        },
        {
            title: t("Visible End Date"),
            dataIndex: "applicationVisibleEndDate",
            filters: false,
            onFilter: false,
            hideInSearch: true,
            renderText(text, record, index, action) {
                if (record.applicationEndDate >= Date.now() || record.applicationVisibleEndDate >= Date.now()) {
                    return <span>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                } else {
                    return <span style={{ color: "lightgrey" }}>{dayjs(text).format("DD/MM/YYYY HH:mm")}</span>
                }
            },
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <>
                        <Button
                            onClick={() => {
                                navigate(`/dashboard/applicationYears/${record.id}`)
                            }}
                            type="link"
                        >
                            {t("View")}
                        </Button>
                        <Button
                            onClick={() => {
                                setCloneModal(record);
                            }}
                            type="link"
                        >
                            {t("Clone")}
                        </Button>
                    </>
                )
            }
        }
    ]

    return (
        <>
            <Breadcrumb
                style={{ marginBottom: 30 }}
                itemRender={(route, params, routes, paths) => {
                    if (route.href) {
                        return <Link to={route.href || "/"}> {route.title} </Link>
                    }
                    return route.title
                }}
                items={[
                    {
                        href: '/dashboard',
                        title: <HomeOutlined />,
                    },
                    {
                        title: t("Application Years"),
                    },
                ]}
            />

            <AntDProTable<ApplicationYears>
                filterType={"light"}
                size={"small"}

                onRefCallback={(ref: any) => {
                    tableRef.current = ref.current
                }}
                pageSizeOptions={[10, 20, 50, 100, 200]}
                columns={columns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                toolBarRender={() => [

                ]}
                request={(
                    params,
                    sort,
                    filter,
                    skip,
                    take,
                    where,
                    sortBy,
                    order,
                    cb
                ) => {
                    if (!sortBy) {
                        sortBy = "applicationStartDate"
                        order = "desc"
                    }
                    applicationService.getApplicationYearGroupList({
                        params,
                        sort,
                        filter,
                        skip,
                        take,
                        where,
                        sortBy,
                        order,
                    }).then(res => {

                        if (res.status === "OK") {
                            cb({
                                data: res.reference.result.map(d => {
                                    return {
                                        id: d.schoolYear + "-" + d.applicationFor,
                                        ...d,
                                    }
                                }),
                                total: res.reference.recordTotal,
                                success: true,
                                page: params.current,
                            })
                        }
                    }).catch(err => {
                        cb({
                            data: [],
                            total: 0,
                            success: false,
                            page: params.current,
                        })
                    })
                }}
            />
            <Modal
                title={t("")}
                centered
                open={openCloneModal != undefined}
                onOk={() => {
                    f1.submit();
                    //
                }}
                onCancel={() => setCloneModal(undefined)}
                width={1000}
            >
                <Form name="timeslotForm" layout="vertical" form={f1} initialValues={openCloneModal} onFinish={(props: ApplicationYears) => {

                    applicationService.cloneApplicationGroup(openCloneModal!.id, {
                        applicationEndDate: props.applicationPeriod && props.applicationPeriod.length > 0 ? props.applicationPeriod[1].toDate().getTime() : 0,
                        applicationFor: props.applicationFor,
                        applicationMode: props.applicationMode,
                        applicationStartDate: props.applicationPeriod && props.applicationPeriod.length > 0 ? props.applicationPeriod[0].toDate().getTime() : 0,
                        applicationVisibleEndDate: props.applicationVisiblePeriod && props.applicationVisiblePeriod.length > 0 ? props.applicationVisiblePeriod[1].toDate().getTime() : 0,
                        applicationVisibleStartDate: props.applicationVisiblePeriod && props.applicationVisiblePeriod.length > 0 ? props.applicationVisiblePeriod[0].toDate().getTime() : 0,
                        schoolYear: props.schoolYear
                    }).then(d => {
                        if (d.status == "OK") {
                            setCloneModal(undefined)
                            tableRef.current?.reload()
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!'
                            })
                        }
                    })
                }}>
                    <Row gutter={16}>


                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item label={t("School Year")} name={"schoolYear"}>
                                <InputNumber
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item label={t("Application For")} name={"applicationFor"}>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    defaultValue={[]}
                                    options={[{
                                        label: t("G1"),
                                        value: "G1",
                                    }, {
                                        label: t("G26"),
                                        value: "G26",
                                    }, {
                                        label: t("G2"),
                                        value: "G2",
                                    }, {
                                        label: t("G3"),
                                        value: "G3",
                                    }, {
                                        label: t("G4"),
                                        value: "G4",
                                    }, {
                                        label: t("G5"),
                                        value: "G5",
                                    }, {
                                        label: t("G6"),
                                        value: "G6",
                                    }]}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item label={t("Application Mode")} name={"applicationMode"}>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    defaultValue={[]}
                                    options={[{
                                        label: t("A"),
                                        value: "A",
                                    }, {
                                        label: t("B"),
                                        value: "B",
                                    }]}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                            <Form.Item label={t("Application Period")} name={"applicationPeriod"}>
                                <RangePicker showTime
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                            <Form.Item label={t("Application Visible Period")} name={"applicationVisiblePeriod"}>
                                <RangePicker showTime
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

