import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Space, Typography, Layout, Select, InputNumber, Row, Col, DatePicker } from "antd";
import { useForm } from "antd/es/form/Form";
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined, ReloadOutlined, SaveOutlined } from "@ant-design/icons";
import "./generalSetting.css";
import { applicationService } from "../../../../services/applicationService";
import { useParams } from "react-router-dom";
import { ApplicationYears } from "../../../../models/applicationYears";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import moment from "moment";
import { convertToChineseDate } from "../../../../tools/datafn";
import { getDemoApplication } from "../../../../tools/demo";

const { Header, Content, Footer, Sider } = Layout;
const { RangePicker } = DatePicker;

export function GeneralSettingPage() {
    const [f1] = useForm();
    const { t } = useTranslation();
    const { id } = useParams();
    const [setting, setSetting] = useState<ApplicationYears>()
    React.useEffect(() => {
        getApplicationGroupSetting()
    }, [])
    function getApplicationGroupSetting() {
        applicationService.getSetting(id || "").then(d => {
            if (d.status == "OK") {
                setSetting(d.reference)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
        })
    }

    useEffect(() => {
        if (setting != undefined && setting.applicationPeriod == undefined) {
            let form = {
                ...setting,
                placeholders: Object.keys(setting.placeHolders || {}).length > 0 ? Object.keys(setting.placeHolders || {}).map((d: string) => { return { key: d, value: setting.placeHolders[d] } }) : [],
                applicationPeriod: [dayjs(setting.applicationStartDate), dayjs(setting.applicationEndDate)],
                applicationVisiblePeriod: [dayjs(setting.applicationVisibleStartDate), dayjs(setting.applicationVisibleEndDate)]
            }
            setSetting(form)
            f1.setFieldsValue(form);
        } 

    }, [setting])
    function resetForm() {
        f1.resetFields()
    }
    return <>
        <Form layout="vertical" form={f1} initialValues={setting} onFinish={(props: ApplicationYears) => {

            applicationService.updateSetting(id || "", {
                applicationEndDate: props.applicationPeriod && props.applicationPeriod.length > 0 ? props.applicationPeriod[1].toDate().getTime() : 0,
                applicationFor: props.applicationFor,
                applicationMode: props.applicationMode,
                applicationStartDate: props.applicationPeriod && props.applicationPeriod.length > 0 ? props.applicationPeriod[0].toDate().getTime() : 0,
                applicationVisibleEndDate: props.applicationVisiblePeriod && props.applicationVisiblePeriod.length > 0 ? props.applicationVisiblePeriod[1].toDate().getTime() : 0,
                applicationVisibleStartDate: props.applicationVisiblePeriod && props.applicationVisiblePeriod.length > 0 ? props.applicationVisiblePeriod[0].toDate().getTime() : 0,
                schoolYear: props.schoolYear,
                placeHolders: props.placeholders?.reduce((a: any, b: any) => ({ ...a, [b.key]: b.value }), {}),
            }).then(d => {
                if (d.status == "OK") {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Saved successfully',
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'
                    })
                }
            })
        }}>
            <Row>

                <Col>
                    <Form.Item>
                        <Button title="Save And Submit" type="link" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item>
                        <Button title="Reload" type="link" htmlType="reset" onClick={() => getApplicationGroupSetting()}>
                            <ReloadOutlined />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("School Year (n - n+1)")} name={"schoolYear"}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("Application For")} name={"applicationFor"}>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            defaultValue={[]}
                            options={[{
                                label: t("G1"),
                                value: "G1",
                            }, {
                                label: t("G26"),
                                value: "G26",
                            }, {
                                label: t("G2"),
                                value: "G2",
                            }, {
                                label: t("G3"),
                                value: "G3",
                            }, {
                                label: t("G4"),
                                value: "G4",
                            }, {
                                label: t("G5"),
                                value: "G5",
                            }, {
                                label: t("G6"),
                                value: "G6",
                            }]}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={24} sm={24} xs={24}>
                    <Form.Item label={t("Application Mode")} name={"applicationMode"}>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            defaultValue={[]}
                            options={[{
                                label: t("A"),
                                value: "A",
                            }, {
                                label: t("B"),
                                value: "B",
                            }]}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={<>{t("Application Period")} (<Link target="_blank" to={`/${id}/application-form`}>{t("Application Form")}</Link>)</>} name={"applicationPeriod"}>
                        <RangePicker showTime
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={<>{t("Application Visible Period")} (<Link target="_blank" to={`/`}>{t("Landing Page")}</Link>)</>} name={"applicationVisiblePeriod"}>
                        <RangePicker showTime
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col>

                </Col>
                <Col className="gutter-row" md={24} sm={24} xs={24}>
                    <p>{t("Placeholders")} <InfoCircleOutlined title="Click to see placeholders example" onClick={() => {
                        var wnd = window.open("about:blank", "", "_blank");
                        if (wnd)
                            wnd.document.write(`<!DOCTYPE html>
                        <html lang="en">
                        <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Placeholder Description</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                            }
                            table {
                                width: 100%;
                                border-collapse: collapse;
                            }
                            table, th, td {
                                border: 1px solid #ddd;
                            }
                            th, td {
                                padding: 10px;
                                text-align: left;
                            }
                            th {
                                background-color: #f2f2f2;
                            }
                        </style>
                        </head>
                        <body>
                        <h1>Placeholder Keys Description</h1>
                        <table>
                            <tr>
                                <th>Placeholder Key</th>
                                <th>Description</th>
                                <th>Example Format</th>
                            </tr>
                            <tr>
                                <td>EmailDate</td>
                                <td>Date in the format of Day Month Year</td>
                                <td>${moment().format("D MMMM YYYY")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_YYYY</td>
                                <td>Year in four digits</td>
                                <td>${moment().format("YYYY")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_YY</td>
                                <td>Year in two digits</td>
                                <td>${moment().format("YY")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_MMMM</td>
                                <td>Month spelled out fully</td>
                                <td>${moment().format("MMMM")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_MMM</td>
                                <td>Month abbreviated to three letters</td>
                                <td>${moment().format("MMM")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_MM</td>
                                <td>Month in two digits</td>
                                <td>${moment().format("MM")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_M</td>
                                <td>Month in one or two digits</td>
                                <td>${moment().format("M")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_DD</td>
                                <td>Day in two digits</td>
                                <td>${moment().format("DD")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_D</td>
                                <td>Day in one or two digits</td>
                                <td>${moment().format("D")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_Do</td>
                                <td>Day of the month with an ordinal suffix</td>
                                <td>${moment().format("Do")}</td>
                            </tr>
                            <tr>
                                <td>EmailDate_Nth</td>
                                <td>Custom function to return a day with a suffix</td>
                                <td>st, nd, rd, th</td>
                            </tr>
                            <tr>
                                <td>EmailDateChi</td>
                                <td>Converts the date to a Chinese date format</td>
                                <td>${convertToChineseDate(new Date(), true)}</td>
                            </tr>
                            <tr>
                                <td>SchoolYear</td>
                                <td>Current school year</td>
                                <td>${setting?.schoolYear||new Date().getFullYear()}</td>
                            </tr>
                            <tr>
                                <td>SchoolYearPlus1</td>
                                <td>Next school year</td>
                                <td>${(setting?.schoolYear||new Date().getFullYear())+1}</td>
                            </tr>
                            ${setting && setting.placeHolders && Object.keys(setting.placeHolders).map(d => {
                                return `<tr>
                                <td>${d}</td>
                                <td>Custom Field</td>
                                <td>${setting.placeHolders[d]}</td>
                            </tr>`
                            }).join('')}
                            ${ Object.keys(getDemoApplication()).map(d => {
                                return `<tr>
                                <td>Application.${d}</td>
                                <td>Application Dependent</td>
                                <td>${getDemoApplication()[d]}</td>
                            </tr>`
                            }).join('')}
                            <tr>
                                <td>FirstInterviewDate</td>
                                <td>Date in the format of Day Month Year</td>
                                <td>${moment().format("DD MMMM YYYY")}</td>
                            </tr>
                            <tr>
                                <td>FirstInterviewTime</td>
                                <td>Time in the format of Hour:Minute am/pm</td>
                                <td>${moment().format("hh:mm a")}</td>
                            </tr>
                            <tr>
                                <td>SecondInterviewDate</td>
                                <td>Date in the format of Day Month Year</td>
                                <td>${moment().format("DD MMMM YYYY")}</td>
                            </tr>
                            <tr>
                                <td>SecondInterviewTime</td>
                                <td>Time in the format of Hour:Minute am/pm</td>
                                <td>${moment().format("hh:mm a")}</td>
                            </tr>
                            <tr>
                                <td>ThirdInterviewDate</td>
                                <td>Date in the format of Day Month Year</td>
                                <td>${moment().format("DD MMMM YYYY")}</td>
                            </tr>
                            <tr>
                                <td>ThirdInterviewTime</td>
                                <td>Time in the format of Hour:Minute am/pm</td>
                                <td>${moment().format("hh:mm a")}</td>
                            </tr>
                            <tr>
                                <td>OfferDate</td>
                                <td>Date in the format of Day Month Year</td>
                                <td>${moment().format("DD MMMM YYYY")}</td>
                            </tr>
                            <tr>
                                <td>OfferTime</td>
                                <td>Time in the format of Hour:Minute am/pm</td>
                                <td>${moment().format("hh:mm a")}</td>
                            </tr>
                        </table>
                        </body>
                        </html>`);
                    }} /></p>
                    <Form.List name="placeholders"  >
                        {(fields, { add, remove }) => {
                            console.log(fields);
                            return <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: -8, width: "100%" }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            style={{ flex: 1 }}
                                            name={[name, 'key']}
                                            rules={[{ required: true, message: 'Key' }]}
                                        >
                                            <Input placeholder="Key" />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ flex: 1 }}
                                            {...restField}
                                            name={[name, 'value']}
                                            rules={[{ required: true, message: 'Value' }]}
                                        >
                                            <Input placeholder="Value" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        {t("Add placeholder")}
                                    </Button>
                                </Form.Item>
                            </>
                        }}
                    </Form.List>
                </Col>

            </Row>
        </Form>
    </>
}