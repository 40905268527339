import React, { useEffect, useState } from 'react';
import {
    DashboardOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SolutionOutlined,
    TranslationOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, MenuProps } from 'antd';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Home } from './home/home';
import { useTranslation } from 'react-i18next';
import { ApplicationYearsListPage } from './applications/applicationYearsList';
import { ApplicationYearPage } from './applications/applicationYear';
import { ApplicationProfilePage } from './applications/applications/application';
import { AllUserListPage } from './users/allusers';
import { NewApplicationPage } from './applications/applications/newApplication';
import { changeLanguage } from 'i18next';

const { Header, Sider, Content } = Layout;

export function Dashboard() {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate()
    const [openKeys, setOpenKeys] = useState(['sub1']);
    const rootSubmenuKeys = ['home', 'users', 'applications', 'logout'];
    const { t } = useTranslation();

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };


    return (

        <Layout style={{ minHeight: '100vh' }}>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="demo-logo-vertical" />
                <Menu
                    theme="dark"
                    mode="inline"
                    onOpenChange={onOpenChange}

                    defaultSelectedKeys={[window.location.pathname.split("/").length >= 3 ? window.location.pathname.split("/")[2] : "home"]}
                    items={[
                        {
                            key: 'home',
                            icon: <DashboardOutlined />,
                            label: (<Link to={"/dashboard/home"}>
                                {t("Dashboard")}
                            </Link>),
                        },
                        {
                            key: 'users',
                            icon: <UserOutlined />,
                            label: (<Link to={"/dashboard/users"}>
                                {t("Users")}
                            </Link>),
                        },
                        {
                            key: 'applications',
                            icon: <SolutionOutlined />,
                            label: (<Link to={"/dashboard/applicationYears"}>
                                {t("Applications")}
                            </Link>),
                        },
                        {
                            key: 'logout',
                            icon: <LogoutOutlined />,
                            label: (<Link to={"/dashboard/logout"}>
                                {t("Logout")}
                            </Link>),
                        },
                    ]}
                />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }}>

                    <Menu
                        theme="light"
                        mode="horizontal"
                        style={{ display: "flex", alignContent: "space-between" }}
                    >

                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                        <Menu.SubMenu
                            key="lang"
                            icon={<TranslationOutlined style={{ fontSize: '24px' }} />}
                        >
                            <Menu.Item
                                key="tc"
                                onClick={() => {
                                    changeLanguage("tc")
                                }}
                            >
                                繁
                            </Menu.Item>
                            <Menu.Item
                                key="en"
                                onClick={() => {
                                    changeLanguage("en")
                                }}
                            >
                                Eng
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                    }}
                >

                    <Routes>
                        <Route path="/home" Component={Home}> </Route>
                        <Route path="/users" Component={AllUserListPage}> </Route>
                        <Route path="/applicationYears">
                            <Route path='/applicationYears/:id'>
                                <Route path='/applicationYears/:id/:app' element={<ApplicationProfilePage />} />
                                <Route path='/applicationYears/:id/newApplication' element={<NewApplicationPage />} />
                                <Route index element={<ApplicationYearPage />} />
                            </Route>
                            <Route index element={<ApplicationYearsListPage />} />
                        </Route>
                        <Route path='/newApplication/:id/' element={<NewApplicationPage />} />
                        <Route
                            path="*"
                            element={<Navigate to="/dashboard/home" replace />}
                        />


                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
};

